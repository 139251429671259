import classes from './Shared.module.scss'
// import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection'
// import DirectionsIcon from '@mui/icons-material/Directions'
// import FmdGoodIcon from '@mui/icons-material/FmdGood'
// import LocationOnIcon from '@mui/icons-material/LocationOn'

interface IAddress {
  where?: string
  addrText?: string
  addClass?: string
}

export default function Directions(props: IAddress) {
  return (
    <a
      href="https://www.google.com/maps/dir/Orange+Beach,+Alabama/Gulf+Shores+Sportsplex,+19025+Oak+Rd+W,+Gulf+Shores,+AL+36542/@30.2889922,-87.7228589,12z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x889a0653ba3f0f09:0xea3bcac5eb6a8dbb!2m2!1d-87.5867598!2d30.2697033!1m5!1m1!1s0x889a1aacc5555555:0xfde98a43e442070e!2m2!1d-87.6985825!2d30.3159001?entry=ttu"
      target="_blank"
      rel="noreferrer"
      className={[classes.bumzLink, classes[props.addClass ?? 'std']].join(' ')}
      type="button"
    >
      {props.addrText ? [props.addrText] : "Directions"}
    </a>
  )
}

export function Address(props: IAddress) {
  return (
    <div className={[classes.addrLink, classes[props.where ?? 'classic']].join(' ')}>
      {props.addrText ? 
        <>
          [props.addText] 
          <br className={classes.cbr}/>
        </>
        : null
      }
      <p>19051 Oak Rd<br/>Unit 123<br/> West Gulf Shores, AL</p>
    </div>
  )
}
