import { Component } from 'react'
import { Routes, Route } from 'react-router-dom'
// import './AppUni.scss' // do we want to do uni styles this way???
import classes from './App.module.scss'
// import { TinyButton as ScrollUpButton } from 'react-scroll-up-button'
import withRouter from './components/WithRouter'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Home from './Pages/Home/Home'
import ComingSoon from './Pages/ComingSoon/ComingSoon'
import Page from './Pages/Page/Page'

class App extends Component {
  render() {
    let routes = (
      <Routes>
        {/* <Route exact={true} path="/" element={<Home />} /> */}
        <Route path="/" element={<ComingSoon />} />
        <Route path="/home" element={<Home />} />
        <Route path="/page" element={<Page />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    )

    let mainClass="fullSite"
    if (window.location.pathname === '/') {
      mainClass="tempSite"
    }

    return (
      <div className={[classes.fullSec, classes.bumzWrap, classes[mainClass]].join(' ')}>
        {window.location.pathname !== '/' && <Header />}
        <main className={[classes.fullSec, classes.mainWrap].join(' ')}>
          {routes}
        </main>
        {/* <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          EasingType='linear'
        /> */}
        {window.location.pathname !== '/' && <Footer />}
      </div>
    )
  }
}
export default App
