import classes from './Page.module.scss'

export default function Page() {
  return (
    <div className={[classes.pageStyles, classes.PAGE].join(' ')}>
      <div className={classes.medDef}>
        <h3>Go crazy!</h3>
      </div>
    </div>
  )
}
