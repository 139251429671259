// import { MouseEvent } from 'react'
// import { Link } from 'react-router-dom'
import classes from './Header.module.scss'
// const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>)


interface INav {
  navClass?: string
  addClass?: string
}

export default function navItems(props: INav){
  function scrollSmoothTo(elementId: string) {
    var element = document.getElementById(elementId)
    element?.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  return (
    <ul className={[classes[props.navClass ?? 'navGroup'], classes[props.addClass ?? 'addClass']].join(' ')}>
      {/* <li className={[classes.navItem, classes.homeLinkXX].join(' ')}><a href="/">Home</a></li>
      <li className={[classes.navItem].join(' ')}><a href="#menu" data-uk-scroll>Menu</a></li>
      <li className={[classes.navItem].join(' ')}><a href="#contact" data-uk-scroll>Contact</a></li> */}

      <li className={[classes.navItem].join(' ')}>
        <button onClick={(e) => scrollSmoothTo('')}>Home</button>
      </li>
      <li className={[classes.navItem].join(' ')}>
        <button onClick={(e) => scrollSmoothTo('menu')}>Menu</button>
      </li>
      <li className={[classes.navItem].join(' ')}>
        <button onClick={(e) => scrollSmoothTo('contact')}>Contact</button>
      </li>
    </ul>
  )
}
