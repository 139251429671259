// import { Link } from "react-router-dom"
import classes from './Footer.module.scss'
// import Social from '../Social/Social'
// import Phone from '../Shared/Phone'
// import Email from '../Shared/Email'
// import Drawer from '../Header/Drawer'
// import footLogo from '../../assets/images/IMAGE.png'

export default function Footer() {
  return (
    <footer className={classes.Footer}>
      <div className={classes.med3Def}>        
        <div className={[classes.ftCol, classes.fc2].join(' ')}>
          {/* <Social where="footer" /> */}
          <p className={[classes.copy, classes.left].join(' ')}>
            © {(new Date().getFullYear())}{' '}
            Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
        </div>

        <div className={[classes.ftCol, classes.fc3].join(' ')}>
          {/* <Link to="/"><img src={footLogo} alt=" Logo" className={[classes.logo1].join(' ')} /></Link> */}
        </div>
      </div>
    </footer>
  )
}





