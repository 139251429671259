import { Link } from 'react-router-dom'
import classes from './Header.module.scss'
import NavItems from './NavItems'
// import Drawer from './Drawer'
import Social from '../Social/Social'
import Phone from '../Shared/Phone'
// import Email from '../Shared/Email'
import Directions from '../Shared/Address'
import logo from '../../assets/images/bumzLogo.png'

export default function Header() {
  return (
    <header className={[classes.Header, classes.ifType].join(' ')}>
      <div className={[classes.inside, classes.hdWrap, classes.OGV].join(' ')}>
        <nav className={[classes.fullSec, classes.navMm].join(' ')}>
          <div className={[classes.hdCol, classes.logoCol].join(' ')}>
            <Link to="/" className={[classes.hdLogo].join(' ')}>
              <img src={logo} alt="Beach Bumz logo" />
            </Link>
          </div>

          <div className={[classes.hdCol, classes.navCol].join(' ')}>
            <NavItems />
            <Social where="nav" />
            {/* <div className={classes.mobNav}><Drawer /></div> */}
          </div>

          <div className={[classes.hdCol, classes.contactCol].join(' ')}>
            <Phone where="header" />
            <Directions addClass="bumzLinkSm" />
          </div>
        </nav>        
      </div>
    </header>
  )
}
