import classes from './Shared.module.scss'

interface IPhone {
  where?: string
  addText?: string
  callText?: string
}

export default function Phone(props: IPhone) {
  return (
    <a className={[classes.callLink, classes[props.where ?? 'classic']].join(' ')} href="tel: +1251XXXXXXX">
      {props.addText ? 
        <>
          [props.addText] 
          <br className={classes.cbr}/>
        </>
        : null
      }
      {props.callText ? [props.callText] : '251.555.5555'}
    </a>
  )
}