import classes from './Social.module.scss'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
// import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'

interface ISocial {
  where?: string
}

export default function Social(props: ISocial) {
  return (
    <ul className={[classes.Social, classes[props.where ?? 'classic']].join(' ')}>
      <li><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
        {/* <span className={classes.SocialIcon} aria-label="Beach Bumz Cafe Facebook Link" uk-icon="icon: facebook"></span> */}
        <FacebookIcon />
        {/* <FacebookOutlinedIcon /> */}
      </a></li>

      <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
        {/* <span aria-label="Beach Bumz Cafe Instagram Link" uk-icon="icon: instagram"></span> */}
        <InstagramIcon />
      </a></li>
    </ul>
  )
}
