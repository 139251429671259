import classes from './ComingSoon.module.scss'
import logo from '../../assets/images/bumzLogo.png'
import bake from '../../assets/images/csMuffin.png'
import blend from '../../assets/images/csSmoothie.png'
import brew from '../../assets/images/csLatte.png'
// import { Wave } from '../../components/Shared/Shapes'

// {/* 
//   coffee, tea, pastries, sandwiches,
//   breakfast burritos, breakfast sandwiches, flatbread pizzas, 
//   smoothies, smoothie Fruit bowls and icecream.  
//   jams, sauces, spices 
// */}
// {/* after /ul
//   <a className="uk-position-center-left uk-position-small uk-hidden-hover" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
//   <a className="uk-position-center-right uk-position-small uk-hidden-hover" data-uk-slidenav-next data-uk-slideshow-item="next"></a> 
// */}


const imageSlider = (
  <div
    className={[classes.ukSlideshow].join(' ')}
    tabIndex={-1}
    data-uk-slideshow="animation:fade; autoplay:true; draggable:false; ratio:1:1; pause-on-hover:false; autoplay-interval:3000;"
  >
    <ul className={[classes.ukSlides, "uk-slideshow-items"].join(' ')}>
      <li><img src={brew} alt="" /></li>
      <li><img src={bake} alt="" /></li>
      <li><img src={blend} alt="" /></li>
    </ul>
  </div>
)

const textSlider = (
  <h2 className={[classes.mainHead].join(' ')}>
    <div
      className={[classes.ukTextSlide].join(' ')}
      tabIndex={-1}
      data-uk-slideshow="animation:fade; autoplay:true; draggable:false; ratio:false; pause-on-hover:false; autoplay-interval:3000;"
    >
      <span>Good things are&nbsp;</span>
      {/* Good things are&nbsp; */}
      <ul className={[classes.ukSlides, "uk-slideshow-items"].join(' ')}>
        <li className={classes.one}> brewing...</li>
        <li className={classes.two}> baking...</li>
        <li className={classes.three}> blending...</li>
      </ul>
    </div>
  </h2>
)


export default function Home() {
  return (
    <div className={[classes.pageStyles, classes.ComingSoon].join(' ')}>
      <div className={[classes.fullSec, classes.treatSliderBtmText].join(' ')}>
        <div className={classes.inside}>
          <div className={[classes.fullSec, classes.bgWrap].join(' ')}>
            <div className={classes.hd1}><img src={logo} alt="Coffee Shop in Gulf Shores, AL - Beach Bumz" /></div>

            <div className={classes.insideTw}>
              <div className={classes.content}>
                <div className={classes.text}>
                  <h1 className={[classes.bigHead, classes.mobBigHead].join(' ')}>Coming December 2024</h1>
                  {textSlider}
                </div>

                {imageSlider}
              </div>

              <div className={classes.mobImgRow}>
                <div className={classes.inside}>
                  <img src={brew} alt="" />
                  <img src={bake} alt="" />
                  <img src={blend} alt="" />
                </div>
              </div>

              <div className={[classes.fullSec, classes.btmTextWrap].join(' ')}>
                <h1 className={[classes.bigHead, classes.fwBigHead].join(' ')}>Coming December 2024</h1>
              </div>
              <div className={classes.clearfix} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}