import classes from './Home.module.scss'
import Phone from '../../components/Shared/Phone'
import Email from '../../components/Shared/Email'
import { Address } from '../../components/Shared/Address'
// import Directions from '../../components/Shared/Address'
import Social from '../../components/Social/Social'
// import slide1 from '../../assets/images/iced2.png'
// import slide2 from '../../assets/images/cupcake1.png'
// import slide3 from '../../assets/images/iced2.png'

export default function Home() {
  return (
    <div className={[classes.pageStyles, classes.Home].join(' ')}>
      <div className={[classes.fullSec, classes.treatSlider].join(' ')}>
        <div className={classes.insideTw}>
          <div className={classes.text}>
            <h1><span>Coming December 2024</span></h1>
          </div>

          <div
            className={[classes.ukSlideshow, "uk-position-relativeXXuk-visible-toggleXXuk-light"].join(' ')}
            tabIndex={-1}
            data-uk-slideshow="animation:fade; autoplay:false; ratio: 1:1;"
          >
            {/* <div className={classes.text}><h1>Coming December 2024</h1></div> */}

            {/* <ul className={[classes.ukSlides, "uk-slideshow-items"].join(' ')}>
              <li><img src={slide3} alt="" /></li>
              <li><img src={slide1} alt="" /></li>
            </ul> */}

            {/* Besides the items you mentioned, breakfast burritos, breakfast sandwiches, flatbread pizzas, smoothies, smoothie Fruit bowls and icecream.   Speciality items too.  in a corner we will speciality items...jams,  sauces, spices, etc.  Beach Bumz shirts */}


            {/* <a className="uk-position-center-left uk-position-small uk-hidden-hover" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
            <a className="uk-position-center-right uk-position-small uk-hidden-hover" data-uk-slidenav-next data-uk-slideshow-item="next"></a> */}
          </div>
        </div>
      </div>

      <div id="menu" className={[classes.fullSec, classes.menuSec].join(' ')}>
        <hr/>
      </div>


      <div id="contact" className={[classes.fullSec, classes.contactSec].join(' ')}>
        <div className={classes.insideTw}>
          <div className={classes.text}>
            <h3>Let's Do Coffee</h3>
            <p><Phone where="foot" /></p>
            <p><Email where="foot" /></p>
            <Address where="foot" />
            <Social where="foot" />
          </div>
        </div>
      </div>
    </div>
  )
}