import classes from './Shared.module.scss'

interface IEmail {
  where?: string
}

export default function Email(props: IEmail) {
  return (
    <a className={[classes.Email, classes[props.where ?? 'classic']].join(' ')} href="mailto:EMAIL@gmail.com">emailaddress@website.com</a>
  )
}